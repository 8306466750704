<template>
  <div>
    <!-- <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          会员管理<i class="el-icon-arrow-right"></i>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->
    <!-- 卡片 -->
    <!-- {{$store.state.config.user_type}} -->
    <el-card style="margin-bottom: 10px">
      <nav class="out">
        <nav class="input">
          <el-input v-model="keyword" size="medium" placeholder="输入昵称，姓名，手机，公司，职位" />
        </nav>
        <nav class="input" style="width: 150px">
          <el-select v-model="type" placeholder="用户类型" size="medium">
            <el-option label="全部" :value="-1" />
            <el-option v-for="(item, index) in userconfig&&userconfig.type" :key="index" :label="item" :value="index" />
          </el-select>
        </nav>
        <nav class="input" style="width: 150px">
          <el-select v-model="business" placeholder="行业" size="medium">
            <el-option label="全部" :value="-1" />
            <el-option v-for="(item, index) in userconfig&&userconfig.business" :key="index" :label="item" :value="index" />
          </el-select>
        </nav>
        <nav class="input" style="width: 150px">
          <el-select v-model="status" placeholder="状态" size="medium">
            <el-option label="全部" :value="-1" />
            <el-option label="禁用" :value="2" />
            <el-option label="启用" :value="1" />
          </el-select>
        </nav>
        <nav class="input" style="width: 150px">
          <el-select v-model="service" placeholder="小助手" size="medium">
            <el-option label="全部" :value="-1" />
            <el-option :label="item.name" :value="item.id" v-for="(item, index) in $store.state.NormalList" :key="item.id"></el-option>
          </el-select>
        </nav>
        <nav class="input" style="width: 150px">
          <el-select v-model="showCircles" placeholder="圈子展示" size="medium">
            <el-option label="全部" :value="-1" />
            <el-option label="展示" :value="1"></el-option>
            <el-option label="不展示" :value="0"></el-option>
          </el-select>
        </nav>
        <nav class="input" style="width: 150px">
          <el-select v-model="certType" placeholder="认证类型" size="medium">
            <el-option label="全部类型" :value="-2" />
            <el-option label="未认证" :value="-1" />
            <el-option label="个人认证" :value="1"></el-option>
            <!-- <el-option label="企业认证" :value="2"></el-option> -->
          </el-select>
        </nav>
        <nav class="input" style="width: 150px">
          <el-select v-model="ugId" placeholder="会员等级" size="medium">
            <el-option label="全部" :value="-1" />
            <el-option v-for="(item, index) in $store.state.config &&
                $store.state.config.userGrade" :key="index" :label="item.name" :value="item.id" />
          </el-select>
          </el-select>
        </nav>
        <nav class="input" style="width:300px">
          <el-date-picker v-model="regDate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd" style="width: 100%" size="medium" />
        </nav>
        <nav class="input" style="width: 80px;" v-show="$store.state.btn_if.indexOf('user_add') != -1">
          <el-button type="primary" size="small" @click="add_user">{{
            '添加会员'
          }}</el-button>
        </nav>
        <nav class="input">
          <el-button size="small" @click="reset">重置</el-button>
          <el-button type="primary" size="small" @click="subimt">{{
            btn ? '搜索' : 'loading..'
          }}</el-button>
        </nav>
      </nav>
    </el-card>
    <el-card class="box-card">
      <el-table :data="userlist" border stripe :default-sort="{ prop: 'id', order: 'descending' }" :header-cell-style="rowClass" @sort-change="sortChange">
        <el-table-column label="ID" prop="id" sortable="custom" />
        <el-table-column label="头像" prop="avatar" width="120px">
          <template slot-scope="{ row }">
            <img :src="
                !!row.avatar
                  ? baseurl + yu + row.avatar
                  : baseurl + yu + '/static/pc/img/avatar.png'
              " alt="" style="width: 80px; height: 80px" @click="showEdit(row.id)" />
            <div  @click="certification( row.businessCard)" v-show="row.businessCard&& $store.state.btn_if.indexOf('user_show_business_card') != -1">
            <el-tag style="cursor: pointer;" size="small" type="warning">认证凭证</el-tag>   
            </div>

          </template>
        </el-table-column>
        <el-table-column label="昵称" prop="nickname" />
        <el-table-column label="姓名" prop="realName" />
        <el-table-column label="等级" prop="gradeName">
          <template slot-scope="{row}">
            <p>{{row.gradeName}}</p>
            <p>{{row.vipEndTime}}</p>
          </template>
        </el-table-column>
        <el-table-column label="职位" prop="job">
        </el-table-column>
        <el-table-column label="职级" prop="jobLevelName"> </el-table-column>
        
        <el-table-column label="公司" prop="company">
        </el-table-column>
        <el-table-column label="用户类型" prop="phone">
          <template slot-scope="{ row }">
            {{ row.typeName.toString()  }}
            /{{ row.businessName.toString() }}
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="ew">
            <!-- {{ scope.row }} -->
            <el-tag v-if="ew.row.status == 1" type="warning" size="small" >正常</el-tag>
            <el-tag v-else type="warning" size="small" >禁用</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="注册时间" prop="createTime" />
        <el-table-column label="备注" prop="remark">
          <template slot-scope="{ row }">
              <Remark :row="row" :val="{url:'/admin/User/editInfo',pw:'user_list_remark'}" :getlist="getuserlist"></Remark>
          </template>
        </el-table-column>
        <el-table-column label="小助手" prop="waId" width="140px">
          <template slot-scope="{ row }">
            <el-select v-model="row.waId" placeholder="小助手" size="medium" multiple :multiple-limit="3" @change="handleInputBlur(row)" :disabled="$store.state.btn_if.indexOf('user_list_service') == -1">
              <el-option :label="item.name" :value="item.id + ''" v-for="(item, index) in $store.state.NormalList" :key="item.id"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <el-button
                type="text"
                icon="el-icon-refresh-left"
                size="mini"
                v-show="scope.row.certStatus==2&&$store.state.btn_if.indexOf('user_cancel_cert') != -1"
                @click="cancelCert(scope.row.id)"
            >撤销认证</el-button>
            
            <el-button
                type="text"
                icon="el-icon-share"
                size="mini"
                style="color:tan"
                v-show="$store.state.btn_if.indexOf('user_top') != -1"
                @click="editTop(scope.row,scope.row.top==0?1:0)"
            >{{scope.row.top==0?'圈子推荐':'取消圈子推荐'}}</el-button>
            <el-button type="text" icon="el-icon-edit" size="mini" class="skyblue" @click="showEdit(scope.row.id)" v-show="$store.state.btn_if.indexOf('user_list_edit') != -1">编辑</el-button>
            <el-button type="text" icon="el-icon-delete" size="mini" class="red" @click="removeuserid(scope.row.id)" v-show="$store.state.btn_if.indexOf('user_list_delete') != -1">删除</el-button>

            <el-button v-if="scope.row.status == 2" type="text" icon="el-icon-setting" size="mini" class="yellow" @click="setRole(scope.row.id, 1)" v-show="$store.state.btn_if.indexOf('user_list_status') != -1">启用
            </el-button>
            <el-button v-else type="text" icon="el-icon-setting" size="mini" class="yellow" @click="setRole(scope.row.id, 2)" v-show="$store.state.btn_if.indexOf('user_list_status') != -1">禁用</el-button>
            <el-button type="text" icon="el-icon-camera" size="mini" class="green" @click="seeTheLink(scope.row.id)" v-show="$store.state.btn_if.indexOf('user_get_contact') != -1">查看联系方式</el-button>

          </template>
        </el-table-column>
      </el-table>
      <el-pagination :current-page="querifo.pagenum" :page-size="querifo.pagesize" layout="total, prev, pager, next" :total="total" background @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </el-card>
    <el-dialog title="添加用户" :visible.sync="addDialogVisible" width="50%" @close="addColse">
      <el-form ref="addFormRef" label-width="140px" :model="editform" :rules="addFormRulue">
        <el-tag size="medium" style="
            margin-bottom: 50px;
            line-height: 30px;
            width: 90px;
            height: 30px;
            text-align: center;
            font-size: 14px;
          ">个人资料</el-tag>
        <el-form-item label="昵称" prop="nickname">
          <el-input v-model="editform.nickname" />
        </el-form-item>
        <el-form-item label="姓名" prop="realName">
          <el-input v-model="editform.realName" />
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-radio-group v-model="editform.sex">
            <el-radio :label="0">未知</el-radio>
            <el-radio :label="1">男</el-radio>
            <el-radio :label="2">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="editform.email" />
        </el-form-item>
        <el-form-item label="电话" prop="phone">
          <el-input v-model="editform.phone" />
        </el-form-item>
        <el-form-item label="QQ号码">
          <el-input v-model="editform.qq" />
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="editform.remark" />
        </el-form-item>
        <el-form-item label="小助手">
          <el-select v-model="editform.waId" placeholder="小助手" size="medium" multiple :multiple-limit="3">
            <el-option :label="item.name" :value="item.id + ''" v-for="(item, index) in $store.state.NormalList" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="微信号" prop="weixin">
          <el-input v-model="editform.weixin" />
        </el-form-item>
        <!-- <el-form-item label="头像">
          <img :src="
              editform.avatar
                ? baseurl + yu + editform.avatar
                : baseurl + yu + '/static/pc/img/avatar.png'
            " class="avatar" />
        </el-form-item> -->
        <el-form-item label="背景图片">
          <up-img :action="action" :banner="editform.backgroundImage" @event="setImg" :tip_text="false"></up-img>
        </el-form-item>
        <el-form-item label="业务介绍" prop="businessInfo">
          <el-input v-model="editform.businessInfo" type="textarea" :rows="3" />
        </el-form-item>
        <el-form-item label="职位" prop="job">
          <el-input v-model="editform.job" />
        </el-form-item>
        <el-form-item label="级别" prop="jobLevel">
          <el-select v-model.trim="editform.jobLevel" placeholder="请选择" style="width: 120px; margin-right: 12px">
            <el-option :label="item" :value="index * 1" v-for="(item, index) in $store.state.config &&
                $store.state.config.userJobLevel" />
          </el-select>
        </el-form-item>
        <el-form-item label="是否展示到圈子" prop="isShowCircles">
          <el-radio-group v-model="editform.isShowCircles">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否展示联系方式" prop="contactType">
          <table>
            <tr>
              <th>
                <el-radio-group v-model="editform.contactType" @change="editform.showContacts = []" style="height: 22px;">
                  <el-radio :label="2">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </th>

              <th>
                <div class="box" v-show="editform.contactType == 1">
                  <el-checkbox-group v-model="editform.showContacts" :min="1">
                    <el-checkbox label="1">手机号</el-checkbox>
                    <el-checkbox label="2">微信</el-checkbox>
                    <el-checkbox label="3">QQ</el-checkbox>
                    <el-checkbox label="4">邮箱</el-checkbox>
                    <el-checkbox label="5">其他联系方式</el-checkbox>
                  </el-checkbox-group>
                </div>
              </th>
            </tr>
            <tr v-show="editform.contactType == 1&&editform.showContacts.indexOf('5')!=-1">
              <td></td>
              <td>
                <div class="box">
                  <el-input v-model="editform.otherContact"></el-input>
                </div>
              </td>
            </tr>
            <tr v-show="editform.contactType == 1">
              <td></td>
              <td>
                <div class="box">
                  至少选中其中两项
                </div>
              </td>
            </tr>
          </table>
        </el-form-item>
        <el-form-item label="会员等级" prop="ugId">
          <el-select v-model.trim="editform.ugId" placeholder="请选择" style="width: 120px; margin-right: 12px">
            <el-option :label="item.name" :value="item.id" v-for="(item, index) in $store.state.config &&
                $store.state.config.userGrade.slice(0)" />
          </el-select>
        </el-form-item>
        <el-tag size="medium" style="
            margin-bottom: 50px;
            line-height: 30px;
            width: 90px;
            height: 30px;
            text-align: center;
            font-size: 14px;
          ">企业信息</el-tag>
        <el-form-item label="企业名称" prop="enterpriseId">
          <el-select v-model="editform.enterpriseId" filterable remote reserve-keyword placeholder="请输入企业名称模糊查询" :remote-method="remoteMethod" :loading="loading" @change="show_one" no-data-text="搜索不到当前输入的企业，请到企业管理中新增企业">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUser">确 定</el-button>
      </span>
    </el-dialog>
    <!-- <el-dialog title="修改用户" :visible.sync="editdialog" width="50%" @close="editclose">
      <el-form ref="editFormRef" label-width="140px" :model="editform" :rules="addFormRulue">
        <el-tag size="medium" style="
            margin-bottom: 50px;
            line-height: 30px;
            width: 90px;
            height: 30px;
            text-align: center;
            font-size: 14px;
          ">个人资料</el-tag>
        <el-form-item label="昵称" prop="nickname">
          <el-input v-model="editform.nickname" />
        </el-form-item>
        <el-form-item label="姓名" prop="realName">
          <el-input v-model="editform.realName" />
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-radio-group v-model="editform.sex">
            <el-radio :label="0">未知</el-radio>
            <el-radio :label="1">男</el-radio>
            <el-radio :label="2">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="editform.email" />
        </el-form-item>
        <el-form-item label="电话" prop="phone">
          <el-input v-model="editform.phone" disabled />
        </el-form-item>
        <el-form-item label="QQ号码">
          <el-input v-model="editform.qq" />
        </el-form-item>

        <el-form-item label="备注">
          <el-input v-model="editform.remark" />
        </el-form-item>
        <el-form-item label="小助手">
          <el-select v-model="editform.waId" placeholder="小助手" size="medium" multiple :multiple-limit="3">
            <el-option :label="item.name" :value="item.id + ''" v-for="(item, index) in $store.state.NormalList" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="微信号" prop="weixin">
          <el-input v-model="editform.weixin" />
        </el-form-item>
        <el-form-item label="头像">
          <img :src="
              editform.avatar
                ? baseurl + yu + editform.avatar
                : baseurl + yu + '/static/pc/img/avatar.png'
            " class="avatar" />
        </el-form-item>
        <el-form-item label="背景图片">
          <up-img :action="action" :banner="editform.backgroundImage" @event="setImg" :tip_text="false"></up-img>
        </el-form-item>
        <el-form-item label="业务介绍" prop="businessInfo">
          <el-input v-model="editform.businessInfo" type="textarea" :rows="3" />
        </el-form-item>
        <el-form-item label="职位" prop="job">
          <el-input v-model="editform.job" />
        </el-form-item>
        <el-form-item label="级别" prop="jobLevel">
          <el-select v-model.trim="editform.jobLevel" placeholder="请选择" style="width: 120px; margin-right: 12px">
            <el-option :label="item" :value="index*1" v-for="item,index in $store.state.config&&$store.state.config.userJobLevel" />
          </el-select>
        </el-form-item>
        <el-form-item label="是否展示到圈子" prop="isShowCircles">
          <el-radio-group v-model="editform.isShowCircles">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否展示联系方式" prop="contactType">
          <table>
            <tr>
              <th>
                <el-radio-group v-model="editform.contactType" @change="editform.showContacts=[]" style="height: 22px;">
                  <el-radio :label="2">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </th>

              <th>
                <div class="box" v-show="editform.contactType == 1">
                  <el-checkbox-group v-model="editform.showContacts" :min="1">
                    <el-checkbox label="1">手机号</el-checkbox>
                    <el-checkbox label="2">微信</el-checkbox>
                    <el-checkbox label="3">QQ</el-checkbox>
                    <el-checkbox label="4">邮箱</el-checkbox>
                    <el-checkbox label="5">其他联系方式</el-checkbox>
                  </el-checkbox-group>
                </div>
              </th>
            </tr>
            <tr v-show="editform.contactType == 1&&editform.showContacts.indexOf('5')!=-1">
              <td></td>
              <td>
                <div class="box">
                  <el-input v-model="editform.otherContact"></el-input>
                </div>
              </td>
            </tr>
            <tr v-show="editform.contactType == 1">
              <td></td>
              <td>
                <div class="box">
                  至少选中其中两项
                </div>
              </td>
            </tr>
          </table>

        </el-form-item>
        <el-form-item label="会员等级" prop="ugId">
          <el-select v-model.trim="editform.ugId" placeholder="请选择" style="width: 120px; margin-right: 12px">
            <el-option :label="item.name" :value="item.id" v-for="(item, index) in $store.state.config &&
                $store.state.config.userGrade.slice(0)" />
          </el-select>
        </el-form-item>
        <el-tag size="medium" style="
            margin-bottom: 50px;
            line-height: 30px;
            width: 90px;
            height: 30px;
            text-align: center;
            font-size: 14px;
          ">企业信息</el-tag>
        <el-form-item label="企业名称">
          <el-select v-model="editform.enterpriseId" filterable remote reserve-keyword placeholder="请输入企业名称模糊查询" :remote-method="remoteMethod" :loading="loading" no-data-text="搜索不到当前输入的企业，请到企业管理中新增企业" style="width: 480px;">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editdialog = false">取 消</el-button>
        <el-button type="primary" @click="edituserinfo">{{
          btn ? '确 定' : 'loading..'
        }}</el-button>
      </span>
    </el-dialog> -->
    <Member ref="member" @getlist="getuserlist"></Member>
    <Slink ref="link"></Slink>
    <!-- 这是分配角色的对话框 -->
        <el-image-viewer
            v-if="showViewer"
             :on-close="()=>{showViewer=false}"
             :url-list="[showurl]" />
  </div>
</template>

<script>
// import $ from 'jquery'
import remark from '../components/remark/index.vue'
import upImg from '../components/upImg/'
import {
  mapState
} from "vuex";
export default {
  components: {
    upImg,
    Member:()=>import('../components/member.vue'),
    Slink:()=>import('../components/seeTheLink.vue'),
    'el-image-viewer':()=>import('element-ui/packages/image/src/image-viewer'),
    Remark:remark
  },
  data() {
    var checkEmail = (rule, value, cb) => {
      // 这是验证邮箱的正则表达式
      const regemail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (regemail.test(value)) {
        return cb()
      }
      cb(new Error('请输入合格邮箱'))
    }
    var checkMoblle = (rule, value, cb) => {
      const regphone = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
      if (regphone.test(value)) {
        return cb()
      }
      cb(new Error('请输入合法的手机号'))
    }

    return {
    showViewer:false,
    showurl:'',
      options: [],
      province: '',
      btn: true,
      baseurl: this.$store.state.env.cos,
      yu: '/' + this.$store.state.env.domain,
      keyWord: '',
      pai: [],
      type: '',
      keyword: '',
      showCircles: '',
      business: '',
      certType: '',
      ugId: '',
      regDate: [],
      querifo: {
        query: '',
        pagenum: 1,
        pagesize: 10
      },
      order: '',
      userlist: [],
      total: 0,
      addDialogVisible: false,
      addform: {
        username: '',
        password: '',
        email: '',
        mobile: ''
      },
      addFormRulue: {
        nickname: [{
          required: true,
          message: '请输入昵称',
          trigger: 'blur'
        }
          // {
          //   min: 3,
          //   max: 10,
          //   message: '用户的长度在3~10个字符直接',
          //   trigger: 'blur'
          // }
        ],
        enterpriseId: [{
          required: true,
          message: '请输入企业名称',
          trigger: 'blur'
        }],

        realName: [{
          required: true,
          message: '请输入姓名',
          trigger: 'blur'
        }],
        phone: [{
          required: true,
          message: '请输入电话',
          trigger: 'blur'
        }],
        weixin: [{
          required: true,
          message: '请输入微信',
          trigger: 'blur'
        }],
        businessInfo: [{
          required: true,
          message: '请输入业务介绍',
          trigger: 'blur'
        }],
        // company: [w
        //   { required: true, message: '请输入公司名称', trigger: 'blur' }
        // ],
        job: [{
          required: true,
          message: '请输入职位',
          trigger: 'blur'
        }],
        // province: [{ required: true, message: '请输入城市', trigger: 'blur' }],
        contactType: [{
          required: true,
          message: '请输入联系方式',
          trigger: 'blur'
        }],
        // type: [{ required: true, message: '请输入类型', trigger: 'blur' }],
        // business: [{ required: true, message: '请输入行业', trigger: 'blur' }],
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        },
        {
          min: 6,
          max: 15,
          message: '用户的长度在6~15个字符直接',
          trigger: 'blur'
        }
        ],
        email: [{
          required: true,
          message: '请输入邮箱',
          trigger: 'blur'
        },
        {
          validator: checkEmail,
          trigger: 'blur'
        }
        ],
        mobile: [{
          required: true,
          message: '请输入手机号',
          trigger: 'blur'
        },
        {
          validator: checkMoblle,
          trigger: 'blur'
        }
        ],
        serch_en_list: [],
        realname: [{
          required: true,
          message: '请输入昵称',
          trigger: 'blur'
        }]
      },
      editdialog: false,
      editform: {
        realName: '',
        nickname: '',
        sex: '',
        qq: '',
        email: '',
        phone: '',
        remark: '',
        avatar: '',
        backgroundImage: '',
        businessInfo: '',
        job: '',
        jobLevel: '',
        isShowCircles: '',
        contactType: [],
        showContacts: [],
        company: '',
        province: '',
        city: '',
        type: [],
        waId: [],
        weixin: '',
        business: [],
        ugId: 3,
        enterpriseId: '',
        otherContact: ''
      },
      setRoledingvisable: false,
      userinfo: '',
      // 所有角色数据列表
      roleslist: [],
      slectroleName: '',
      Config: null,
      status: '',
      service: '',
      paiform: {},
      header: {
        Token: sessionStorage.getItem('token')
      },
      action: {
        action: 'user'
      },
      addEnData: {
        keyword: '',
        page: 1,
        pageSize: 20
      },
      city: '',
      loading: false,
      serch_en: '',
      serch_en_list: [],
    }
  },

  computed: {
    citys() {
      return async function (val) {
        console.log(val)

        const {
          data: res
        } = await this.$http.get(
          '/admin/Area/getList?code=' + val
        )
        console.log(res)
        return {
          code: 5,
          sds: 3
        }

      }
    },
    ...mapState({
      userconfig: 'config'
    })

  },
  created() {
    this.getuserlist()
    this.getear()
    
    // console.log(this.$store.state.imgUrl)
  },

  methods: {
      seeTheLink(val){
          this.$refs.link.getTheDetails(val)
      },
     async editTop(row,val){
        let {data:res} = await this.$http.post('/admin/User/editTop',{id:row.id,top:val})
        if(res.errorCode==200){
        this.$message.success(res.message)
        this.getuserlist()
        }else{
        this.$message.error(res.message)
        }
      },
    add_user() {
    //   this.$options.data().editform
    // console.log(this.$data);

    this.editform=this.$options.data.call(this).editform

      this.addDialogVisible = true
    },
   async cancelCert(row){
     let {data:res} = await  this.$http.post('/admin/User/cancelCert',{id:row})
     if(res.errorCode==200){
     this.$message.success(res.message)
     this.getuserlist()
     }else{
     this.$message.error(res.message)
     }
    },
    remoteMethod(qu) {
      if (qu != '') {
        this.loading = true
        this.$http
          .get('/admin/enterprise/getEnterpriseByName', {
            params: {
              ...this.addEnData,
              keyword: qu
            }
          })
          .then(({
            data: res
          }) => {
            this.loading = false
            if (res.errorCode == 200) {
              console.log(res.data)
              this.options = []
              this.serch_en_list = res.data
              res.data.forEach(item => {
                this.options.push({
                  value: item.id,
                  label: item.company
                })
              })
              // this.$forceUpdate()
            } else {
              this.$message.error(res.message)
            }
          })
      }
    },
    show_one(val) {
      var arr = this.serch_en_list.find(item => item.id == val)
      this.editform.company = arr.company
    },
    certification(val){
        if(!val){
            this.$message.error('当前无凭证')
            return

        }
        this.showViewer=true
        this.showurl=this.baseurl + this.yu+val
    },
    setImg(val) {
      this.editform.backgroundImage = val
    },
    op() {
      this.getc()
      this.editform.city = ''
    },

    async handleInputBlur(row) {
      this.paiform.id = row.id
      this.paiform.remark = row.remark
    //   console.log(this.paiform.waId);
      this.paiform.waId = row.waId.toString()
      console.log(this.paiform)
      const {
        data: res
      } = await this.$http.post(
        '/admin/User/editInfo',
        this.paiform
      )
      if (res.errorCode == 200) {
        this.$message.success(res.message)
        //  row.pai=false
        //  this.tableData=[...this.tableData]
        this.getuserlist()
      } else {
        this.$message.error(res.message)
      }
    },
    rowClass() {
      return 'background:#F5F7FA;'
    },
    getc() {
      this.$http
        .get('/admin/Area/getList?code=' + this.editform.province)
        .then(({
          data: res
        }) => {
          if (res.errorCode == 200) {
            console.log(this.editform.province)
            console.log(res)
            this.city = res.data
          }
        })
    },

    sortChange(c) {
      console.log(c)
      if (c.order) {
        var s = 'user_' + c.prop
        if (c.prop == 'orderNum') {
          s = 'activity_order_num'
        } else {
          s = 'user_' + c.prop
        }
        var p = c.order.slice(0, c.order.length - 6)
        this.order = s + ' ' + p
      } else {
        this.order = ''
      }

      this.getuserlist()
    },
    subimt() {
      this.querifo.pagenum = 1
      this.getuserlist()
      
    },
    async getuserlist() {
      const {
        data: res
      } = await this.$http.get('/admin/User/getList', {
        params: {
          page: this.querifo.pagenum,
          pageSize: this.querifo.pagesize,
          keyword: this.keyword,
          order: this.order,
          type: this.type,
          status: this.status,
          waId: this.service,
          showCircles: this.showCircles,
          certType: this.certType,
          business: this.business,
          ugId: this.ugId,
          regDate: this.regDate?this.regDate.join('~'):''
        }
      })

      if (!this.Config) {

        this.Config = this.$store.state.config
      }
      if (res.errorCode != 200) {
        return this.$message.error(res.message)
      }
      this.userlist = res.data.list
      this.userlist.forEach(item => {
        if (!!item.waId) {
          item.waId = item.waId.split(',')
        }
      })
      this.total = res.data.totalCount
      // console.log(res)
    },
    handleSizeChange(e) {
      // console.log(e)
      this.querifo.pagesize = e
      this.getuserlist()
    },
    handleCurrentChange(e) {
      // console.log(e)
      this.querifo.pagenum = e
      this.getuserlist()
    },
    async userstatechange(userinfo) {
      // 监听switch开关状态改变
      // console.log(userinfo)
      const {
        data: res
      } = await this.$http.put(
        `users/${userinfo.id}/state/${userinfo.mg_state}`
      )
      // console.log(res)
      if (res.meta.status != 200) {
        userinfo.mg_state = !userinfo.mg_state
        return this.$message.error('更新用户失败')
      }
      this.$message.success('更新用户成功')
    },
    addColse() {
      this.$refs.addFormRef.resetFields()
    },
    editclose() {
      this.$refs.editFormRef.resetFields()
    },
    addUser() {
      console.log(this.editform);
      this.$refs.addFormRef.validate(async val => {
        // console.log(val)
        if (!val) return
        const {
          data: res
        } = await this.$http.post('/admin/user/add', {
          ...this.editform,
          business: this.editform.business.toString(),
          showContacts: this.editform.showContacts.toString(),
          type: this.editform.type.toString(),
          waId: this.editform.waId.toString()
        })
        if (res.errorCode != 200) {
          this.$message.error(res.message)
          return
        }
        this.$message.success('添加用户成功')
        this.addDialogVisible = false
        this.getuserlist()
      })
    },
    reset() {
    Object.assign(this.$data, this.$options.data.call(this));
      this.getuserlist()
    },
    async showEdit(id) {
      // console.log(id)
      this.editform.id = id
      const {
        data: res
      } = await this.$http.get('/admin/User/getById?id=' + id)
      if (res.errorCode != 200) {
        return this.$message.error('查询用户失败')
      }
    this.$refs.member.editform = {
        ...res.data,
        waId: res.data.waId && res.data.waId.toString().split(','),
        showContacts:
          (res.data.showContacts &&
            res.data.showContacts.toString().split(',')) ||
          [],
        jobLevel: res.data.jobLevel || '',
        enterpriseId: res.data.enterpriseId || ''
      }
      if (res.data.enterpriseId) {
        this.$refs.member.options = [
          { value: res.data.enterpriseId, label: res.data.company }
        ]
      }

      this.$refs.member.addDialogVisible = true
    //   this.editform = {
    //     ...res.data,
    //     waId: res.data.waId && res.data.waId.toString().split(','),
    //     showContacts: res.data.showContacts && res.data.showContacts.toString().split(',') || [],
    //     jobLevel: res.data.jobLevel || '',
    //     enterpriseId: res.data.enterpriseId || ''
    //   }
    //   // this.serch_en=this.editform.company
    //   // console.log(this.editform.business);

    //   this.editform.business = this.editform.business && this.editform.business.split(',') || ''
    //   this.editform.type = this.editform.type && this.editform.type.split(',') || ''
    //   // console.log(this.editform.business)
    //   if (this.editform.enterpriseId) {
    //     this.options = [{
    //       value: this.editform.enterpriseId,
    //       label: this.editform.company
    //     }]

    //   }

    //   if (this.editform.province) {
    //     this.getc()
    //   }
    //   this.editdialog = true
    },
    clear() {
      this.editform.business = []
      console.log(this.editform)
    },
    edituserinfo() {
      const form = {
        ...this.editform,
        business: this.editform.business.toString(),
        waId: this.editform.waId.toString(),
        showContacts: this.editform.showContacts.toString(),
        type: this.editform.type.toString()
      }
      // console.log(1)
      if (this.btn) {
        this.$refs.editFormRef.validate(async val => {
          if (!val) return
          const {
            data: res
          } = await this.$http.post('/admin/User/edit', form)
          if (res.errorCode == 200) {
            this.$message.success('更新成功')
            console.log(this.editform)
            this.editdialog = false
            this.getuserlist()
          } else {
            this.$message.error(res.message)
          }
        })
      }
      // this.btn = false
    },

    async removeuserid(id) {
      // console.log(id)
      await this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$http
            .delete('/admin/User/del', {
              params: {
                id: id
              }
            })
            .then(({
              data: res
            }) => {
              if (res.errorCode == 200) {
                this.$message.success(res.message)
                if (this.total % 10 == 1) {
                  this.querifo.pagenum--
                }
                this.getuserlist()
              } else {
                this.$message.error(res.message)
              }
            })
            .catch(() => {
              return this.$message.error('删除用户失败')
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 这是展示分配角色的对话框
    setRole(id, info) {
      this.$http
        .post('/admin/User/updateStatus', {
          id: id,
          status: info
        })
        .then(({
          data: res
        }) => {
          if (res.errorCode == 200) {
            this.$message.success(res.message)
            this.getuserlist()
          } else {
            this.$message.error(res.message)
          }
        })
    },
    saveinRole() {
      if (!this.slectroleName) {
        return this.$message.error('请选择你要分配的角色')
      }
      this.$http
        .put(`users/${this.userinfo.id}/role`, {
          rid: this.slectroleName
        })
        .then(() => {
          this.$message.success('更新角色成功')
          this.getuserlist()
          this.setRoledingvisable = false
        })
        .catch(() => {
          this.$message.error('更新角色失败!')
        })
    },
    setRoleclosed() {
      this.slectroleName = ''
    },
    handleAvatarSuccess(res) {
      this.editform.avatar = res.data.url
    },
    getear() {
      this.$http.get('/admin/Area/getList').then(({
        data: res
      }) => {
        if (res.errorCode == 200) {
          this.province = res.data
          // console.log(this.editform.province)
        }
      })
    }
  },
  watch: {
    // editform(newValue, oldValue) {

    //   var arr=  this.serch_en_list.find(item=>item.id==newValue.enterpriseId)
    //   console.log(arr);
    //   //   this.editform={
    //   //   ...this.editform,
    //   //   company:arr.company,
    //   //   province:arr.provinceCode,
    //   //   city:arr.cityCode,
    //   //   type:arr.typeId.toString().split(','),
    //   //   business:arr.businessId.toString().split(','),
    //   // }

    // }
  },
}
</script>

<style lang="less" scoped>
.box {
  display: inline-block;
  margin-left: 15px;
}

.skyblue {
  color: skyblue;
}

.green {
  color: green;
}

.yellow {
  color: pink;
}

.red {
  color: red;
}
</style>
